import { Language } from "../language";
import { getRoute } from "@environment/type";

export const homeMeta = {
  title: Language.meta.homePage,
  requiredAuth: true,
  footerShow: true,
  headerHide: true,
};

export default [
  //首页
  {
    path: getRoute() + "home",
    name: "Home",
    component: () => import("@/views/main/home/Home.vue"),
    meta: homeMeta,
  },
  //关注
  {
    path: getRoute() + "focus",
    name: "Focus",
    component: () => import("@/views/store/index.vue"),
    children: [
      {
        path: "",
        redirect: getRoute() + "focus/follow",
      },
      {
        path: getRoute() + "focus/follow",
        name: "Follow",
        component: () => import("@/views/store/follow/follow.vue"),
        meta: {
          title: Language.meta.followPage,
          requiredAuth: true,
          footerShow: true,
          leftBtnHide: true,
          headerHide: true,
        },
      },
      {
        path: getRoute() + "focus/postedProduct",
        name: "postedProduct",
        component: () =>
          import("@/views/store/share-product/postedProduct.vue"),
        props: true,
        meta: {
          title: Language.userInterpret.ShippingSource,
          requiredAuth: true,
          headerHide: true,
          footerShow: true,
        },
      },
      {
        path: getRoute() + "focus/shareProduct",
        name: "ShareProduct",
        component: () => import("@/views/store/share-product/ShareProduct.vue"),
        props: true,
        meta: {
          title: Language.userInterpret.ShippingSource,
          requiredAuth: true,
        },
      },
    ],
  },
  //用户中心
  {
    path: getRoute() + "user",
    name: "User",
    component: () => import("@/views/user/index.vue"),
    children: [
      {
        path: "",
        redirect: getRoute() + "user/userManage",
      },
      {
        path: getRoute() + "user/userManage",
        name: "UserManage",
        component: () => import("@/views/user/user-manage/UserManage.vue"),
        meta: {
          title: Language.meta.userPage,
          requiredAuth: true,
          footerShow: true,
          headerHide: true,
        },
      },
      {
        path: getRoute() + "user/setUser",
        name: "setUser",
        component: () => import("@/views/user/set-user/SetUser.vue"),
        meta: {
          title: Language.userInterpret.Settings,
          requiredAuth: true,
        },
      },
      {
        path: getRoute() + "user/editNickname",
        name: "EditNickname",
        component: () => import("@/views/user/edit-nickname/EditNickname.vue"),
        meta: {
          title: Language.userInterpret.editName,
          requiredAuth: true,
        },
      },
    ],
  },
    // 优惠券
    {
      path: getRoute() + "coupon",
      name: "coupon",
      component: () =>
        import("@/views/store/coupon/coupon.vue"),
      meta: {
        title: Language.coupon,
        requiredAuth: true,
      },
    },
  // 我的粉丝
  {
    path: getRoute() + "fans",
    name: "fans",
    component: () =>
      import("@/views/store/fans/fans.vue"),
    meta: {
      title: Language.fans,
      requiredAuth: true,
    },
  },
  //消息中心
  {
    path: getRoute() + "messages",
    name: "Messages",
    component: () => import("@/views/user/messages/messages.vue"),
    meta: {
      title: Language.messages.messageCenter,
      requiredAuth: true,
    },
  },
  {
    path: getRoute() + "messageInfo/:id",
    name: "MessageInfo",
    component: () => import("@/views/user/messageInfo/messageInfo.vue"),
    props: true,
    meta: {
      title: Language.messages.messageCenter,
      requiredAuth: true,
    },
  },
  //邀请
  {
    path: getRoute() + "invite",
    name: "Invite",
    component: () => import("@/views/user/invite/invite.vue"),
    meta: {
      title: Language.userInterpret.Invite,
      requiredAuth: true,
    },
  },
  //商品详情
  {
    path: getRoute() + "details",
    name: "details",
    component: () => import("@/views/main/details/Details.vue"),
    meta: {
      title: Language.meta.productDetails,
      requiredAuth: true,
    },
  },
  //供应商详情
  {
    path: getRoute() + "drawableInfo",
    name: "drawableInfo",
    component: () => import("@/views/drawable/drawableinfo/DrawableInfo.vue"),
    meta: {
      title: Language.meta.supplierDetails,
      requiredAuth: true,
    },
  },
  // 收益
  {
    path: getRoute() + "earnings",
    name: "earnings",
    component: () => import("@/views/store/earnings/Earnings.vue"),
    meta: {
      title: Language.meta.earnings,
      requiredAuth: true,
      show: true,
    },
  },
  // 我的小店
  {
    path: getRoute() + "MyStore",
    name: "MyStore",
    component: () => import("@/views/store/my-store/MyStore.vue"),
    meta: {
      title: Language.meta.myStore,
      requiredAuth: false,
    },
  },
  // 商品管理
  {
    path: getRoute() + "commodityManage",
    name: "commodityManage",
    component: () =>
      import("@/views/store/commodity-manage/CommodityManage.vue"),
    meta: {
      title: Language.meta.commodityManage,
      requiredAuth: true,
    },
  },
  //买家订单
  {
    path: getRoute() + "indent",
    name: "indent",
    component: () => import("@/views/store/indent/Indent.vue"),
    meta: {
      title: Language.meta.indent,
      requiredAuth: true,
    },
  },
  // 经营分析Analyze
  {
    path: getRoute() + "analyze",
    name: "analyze",
    component: () => import("@/views/store/analyze/Analyze.vue"),
    meta: {
      title: Language.meta.operatingData,
      requiredAuth: true,
    },
  },
  //订单详情
  {
    path: getRoute() + "indentDetails",
    name: "indentDetails",
    component: () => import("@/views/store/indent-details/IndentDetails.vue"),
    meta: {
      title: Language.meta.indentDetails,
      requiredAuth: true,
    },
  },
  //提现
  {
    path: getRoute() + "withdraw",
    name: "withdraw",
    component: () => import("@/views/store/withdraw/Withdraw.vue"),
    meta: {
      title: Language.meta.withdraw,
      requiredAuth: true,
    },
  },
  //搜索页
  {
    path: getRoute() + "search",
    name: "search",
    component: () => import("@/views/main/search/Search.vue"),
    meta: {
      title: Language.meta.search,
      requiredAuth: true,
    },
  },
  {
    path: getRoute() + "searchHistory",
    name: "searchHistory",
    component: () => import("@/views/main/search-history/SearchHistory.vue"),
    meta: {
      title: Language.meta.search,
      requiredAuth: true,
      headerHide: true,
    },
  },
  //翻译
  {
    path: getRoute() + "interpret",
    name: "interpret",
    component: () => import("@/views/store/interpret/Interpret.vue"),
    meta: {
      title: Language.meta.interpret,
      requiredAuth: true,
    },
  },
  //修改价格
  {
    path: getRoute() + "editPrice",
    name: "editPrice",
    component: () => import("@/views/store/edit-price/EditPrice.vue"),
    meta: {
      title: Language.meta.setPrice,
      requiredAuth: true,
    },
  },
  //我的小店
  {
    path: getRoute() + "store",
    name: "store",
    component: () => import("@/views/client/store/Store.vue"),
    meta: {
      title: Language.meta.myStore,
      requiredAuth: true,
    },
  },
  //编辑我的小店
  {
    path: getRoute() + "editStore",
    name: "EditStore",
    component: () => import("@/views/store/edit-store/EditStore.vue"),
    meta: {
      title: Language.meta.editMyStore,
      requiredAuth: true,
    },
  },
  //Privacy
  {
    path: getRoute() + "privacy",
    name: "privacy",
    component: () => import("@/views/main/privacy/Privacy.vue"),
    meta: {
      //declaration
      title: Language.meta.declaration,
      requiredAuth: true,
    },
  },
  {
    //support
    path: getRoute() + "support",
    name: "support",
    component: () => import("@/views/main/support/Support.vue"),
    meta: {
      title: Language.support.helpCenter,
      requiredAuth: true,
    },
  },
  {
    path: getRoute() + "user/supportList",
    name: "SupportList",
    component: () => import("@/views/main/support/SupportList.vue"),
    meta: {
      title: Language.support.helpCenter,
    },
  },
  {
    path: getRoute() + "user/supportDetail/:id",
    name: "SupportDetail",
    props: true,
    component: () => import("@/views/main/support/SupportDetail.vue"),
    meta: {
      title: Language.support.helpCenter,
    },
  },
  {
    path: getRoute() + "timeActivities",
    name: "TimeActivities",
    component: () => import("@/views/main/time-activities/TimeActivities.vue"),
    meta: {
      title: Language.meta.productList,
      requiredAuth: true,
    },
  },

  {
    path: getRoute() + "auth",
    component: (_) => import("@view/main/auth/Index.vue"),
    children: [
      {
        path: "",
        redirect: "login",
      },
      //登录
      {
        path: getRoute() + "auth/login",
        name: "Login",
        component: () => import("@/views/main/auth/Login.vue"),
        meta: {
          title: Language.loginInterpret.login,
          headerHide: true,
        },
      },

      //注册
      {
        path: getRoute() + "auth/register",
        name: "Register",
        component: () => import("@/views/main/auth/Register.vue"),
        meta: {
          title: Language.registerInterpret.register,
          headerHide: true,
        },
      },

      {
        path: getRoute() + "auth/regInfo",
        name: "RegInfo",
        component: () => import("@/views/main/auth/RegInfo.vue"),
        meta: {
          title: Language.registerInterpret.register,
          headerHide: true,
        },
      },
      {
        path: getRoute() + "auth/forget1",
        name: "Forget1",
        component: (_) => import("@/views/main/auth/ResetPasswordStep1.vue"),
        meta: {
          title: Language.forgetInterpret.forgetPassword,
          headerHide: true,
        },
      },
      {
        path: getRoute() + "auth/forget2",
        name: "Forget2",
        component: (_) => import("@/views/main/auth/ResetPasswordStep2.vue"),
        meta: {
          title: Language.forgetInterpret.validEmail,
          headerHide: true,
        },
      },
      {
        path: getRoute() + "auth/forget3",
        name: "Forget3",
        component: (_) => import("@/views/main/auth/ResetPasswordStep3.vue"),
        meta: {
          title: Language.forgetInterpret.setNewPassword,
          headerHide: true,
        },
      },
      {
        path: getRoute() + "auth/forget4",
        name: "Forget4",
        component: (_) => import("@/views/main/auth/ResetPasswordStep4.vue"),
        meta: {
          title: Language.forgetInterpret.finishPasswrod,
          headerHide: true,
        },
      },
    ],
  },
  {
    path: getRoute() + "authentication",
    name: "Authentication",
    component: () => import("@/views/main/authentication/authentication.vue"),
    meta: {
      title: Language.authenticationInfo.auth,
      requiredAuth: true,
    },
  },
  {
    // 类目搜索
    path: getRoute() + "searchClass",
    name: "SearchClass",
    component: () => import("@/views/main/search-class/SearchClass.vue"),
    props: true,
    meta: {
      title: Language.meta.search,
      requiredAuth: true,
    },
  },
  // 全部类目
  {
    path: getRoute() + "allProducts",
    name: "AllProducts",
    component: () => import("@/views/main/all-products/AllProducts.vue"),
    props: true,
    meta: {
      title: "All Products",
      requiredAuth: true,
    },
  },
  //我的供应商
  {
    path: getRoute() + "supplier",
    name: "Supplier",
    component: () => import("@/views/store/supplier/Supplier.vue"),
    props: true,
    meta: {
      title: Language.userInterpret.MySupplier,
    },
  },
  {
    path: getRoute() + "terms",
    name: "Terms",
    component: () => import("@/views/main/terms/Index.vue"),
    children: [
      {
        path: "",
        redirect: getRoute() + "terms/agreement",
      },
      {
        path: getRoute() + "terms/agreement",
        name: "Agreement",
        component: () => import("@/views/main/terms/Agreement.vue"),
        meta: {
          title: Language.meta && Language.meta.Agreement,
          headerHide: true,
        },
      },
      {
        path: getRoute() + "terms/privacy",
        name: "Privacy",
        component: () => import("@/views/main/terms/Privacy.vue"),
        meta: {
          title: Language.meta && Language.meta.privacy,
          headerHide: true,
        },
      },
    ],
  },
];
