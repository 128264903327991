import axios from "axios";
// import { type } from "@environment/index";
import Loacle from "../language/index";
// import login from '../pages/supplier/store/login'
// let language = localStorage.getItem("language")
//   : "zh";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASH_URL,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": Loacle.language,
  },
});
// var token = localStorage.setItem(response.data.UserName, response.data.Ticket)
// http request 请求拦截器，有token值则配置上token值

instance.interceptors.request.use(
  (config) => {
    // config.url = config.url + "?newData=" + Date.now();
    // const language = localStorage.getItem("language")
    //   ? localStorage.getItem("language")
    //   : "en";
    // let newData = config.data;
    // newData.language = language;
    // config.data = newData;
    // config.data.language = Loacle.language;
    // if (config.url === "api/B_Login/B_UserLogin") {
    //   return config;
    // }
    // if (config.url === "api/C_Shop/LoadShop") {
    //   return config;
    // }
    // let { token } = JSON.parse(localStorage.getItem("login")) || {
    //   token:
    //     "4549B9CB5032D095C825416805F009B472A115BD75AC2F2D1636F542E64D8A116CC2C2621AB8213C0CCDD6F3F0FE2381AF3AD37DD08E59AED8C021837AEC9B0B2F4EE940E13826B32C981E030481493A4A23201E981CBC7B4B0F5EE908F83809CD59FE57BF6ACBE97246A981DD7E3641F358DBE21651F10D14A8A9420D1B3342BCD467434117AAEC1E7399CA7E91F83B1EE0E804154DA8140442CCA712ED369289A2E0963C4378C5E259B795C2F701825804D16EB723B4037EC3F8B0E420EA6016F485F64416295CFD7883827AC4EE5B57BCF7AB0FE6D1F06AA1AA1FEF2FEA5ED3C275FA8F5CC69E5E783864F11A1F38"
    // };
    // if (type === "Vue") {
    //   token =
    //     // "A5EE020801E20D13587CD6CC6E5E5DCF20F151E3F56FC124940D52BB40C8ED20FB2F8D535CE1F3634F396101D8173C45B62B0D7A693C4ED4D508D990B338D1BD8E22A8E0834F63CED12FAAB5109D562FCE633BFF40366B7AEB987A6F4516E2FE15DB9DCA04F164A32AF12C3BD653CD65DF3DF87E03D2F9E286B3AE2AF0AE1C3EFBB9CA3BDF539ACCD4AEF7DD67FEA45EFCF54588E285DD4F31AC12DDEA0AB7DD28778D2F29E07762C6FDCE3E53A457AB68E3C3528BF6B4A940AF2CBFD2F2C208A6AB77B0A829F092DF3D8B9382FC6C763734EC605EDBEC21B47A1CF7EB3E1F663675F2F5F5C6D665C672D60AA0EFA3DD8D490EA8F3D96797A824468C6E85880C";
    //     // "1F49815C59CE04A2855A2C9B199550F231F3CA1C8FB158289571439D68150964299314601EAD6C582E74C5B8653F2F801F58EEF5224F3FE2A4B27BCEA3373275B385A00DB7E361F659FDB4487D1E97F07A5F1C536AB9B04E62A119435A029BE82BB595F57A448DB2983709950F66D61682C600B1BC3DC7A2F9FB4098BF68CC855687C7F95395D6C5FAB52D6D5227D73B85CAFC62141113047B881EC4E0BFB9E454D00A234AD6F71967EC877340524CA497A981E71ABBF6BDC15889CF39CE064D8D202BD9EA9A2507835A8F6479C6476E4C71F94CAF3A5B1B7BED435E6E686DCC859DC5DB4534D608ABD74CA454E4AB8A62BC8EFB96384F9F30AE7B20B8CE9C3E";
    //     "6AE37EEDD4691E602D20FC1E6776009FADEAE5DF39F06370E14274ACEB91BA1486158BAA873A047B0D9D5B906BCF67A7839B5A67C653E7347A8FA2F186C4D93E05B4AAFB130F4AB35C31E61BC286CF03DF37B0F3D9463211BD71D1C4A6039041ABBFA6451727709735DCFE31B1A144EBF73915DBE996DDF643209DF83B5EC4E761D6EC381DAFFE915A133E74D421A0B9DB08E118E730B3E7BB7CD461CC61F1875CA9A6FD4695A8D0CB93FBE3C0D608F8D2ABC7AABBB9099F30293F606ED2C58C94799388FE5520E923EFABB52329518F3143F0C28E0402673CBF928F68A9EB4DB293F5ADB302F9664313B4C3DE5A573CB54D69DCC9FAE3500DEEB130F0C4E1C2";
    // } else {
    //   token = localStorage.getItem("token");
    // }
    let { token } = JSON.parse(localStorage.getItem("auth")) || {};
    if (token) {
      // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
      config.headers.Authorization = "Bearer " + token;
    }
    // const { userName, token } = JSON.parse(localStorage.getItem('login')) || { userName: null, token: null }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.StatusCode === 200) {
      return {
        code: 200,
        data: data.Data,
        message: data.Message,
        meta: {
          page: data.PageIndexs,
          total: data.DataCounts,
          lastPage: data.ALLPages,
        },
      };
    } else {
      const error = { code: data.StatusCode, message: data.Message };
      return Promise.reject(error);
    }
  },
  (error) => {
    let customError;
    if (error && error.response && error.response.data) {
      // if (
      //   error.response.data.StatusCode === 401 ||
      //   error.response.data.StatusCode === 403
      // ) {
      //   router.push({
      //     path: "",
      //   });
      // }
      if (
        error.response.data.StatusCode === 403 ||
        error.response.data.StatusCode === 401
      ) {
        // 登录过期删除用户缓存信息
        localStorage.removeItem("auth");
      }
    } else {
      customError = { code: 0, message: "网络错误", status: 400 };
    }
    // console.log(error)

    // console.log('Error', error.response)
    return Promise.reject(customError);
  }
);

export default instance;
