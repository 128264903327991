export const actionType = {
  showNotify: "showNotify", // 通知
  hideNotify: "hideNotify",
  showDialog: "showDialog", // 弹框
  hideDialog: "hideDialog",
  showToast: "showToast", // 轻提示
  hideToast: "hideToast",
  showLoading: "showLoading",
  hideLoading: "hideLoading",
  shareGoods: "shareGoods", // 分享（安卓方法）
  pop: "pop", // 返回上一级路由
  routerPush: "routerPush", // 路由
  picturePreview: "picturePreview", // 预览图片
  downloadImage: "downloadImage",//下载图片
  transmission: "transmission", //, 透传消息{msg: '说明',status: 状态码}403为跳转登录 601为添加购物车成功
  backHome: "backHome", // 返回首页
  backUser: "backUser", //返回用户中心
  newscenter: "newscenter", //消息中心
  openAlbum: "openAlbum", // 打开相册
  userToken: "userToken", // 安卓存token
  makePhoneCall: "makePhoneCall", //安卓打电话
  clickCopy: "clickCopy", //复制文本
  gotoPay: "gotoPay", //跳转信用卡支付
  shareShop: "shareShop", //分享小店
  exitapp: "exitapp" //退出APP
};
//this.$commonEnv.actionType
//method-type
//methodType
