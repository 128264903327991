import router from "@router";
import { getRoute } from "@environment/type";
import { Language } from "@/language/index.js";
import { customAlert } from "@/common/commonMethod.js";

export const routerPush = (params) => {
  const { path, query, isParams } = params;
  console.log(params);
  let newQuery = {};
  query.forEach((item) => {
    newQuery[item.name] = item.value;
  });
  console.log(newQuery);
  if (isParams === true) {
    router.push({
      path: "/" + path,
      query: newQuery,
    });
  } else {
    router.push({
      path: "/" + path,
    });
  }
};
// 分享商品
export const shareGoods = (params) => {
  console.log(params);
};
// 返回上一路由
export const pop = () => {
  router.go(-1);
};

// 返回首页
export const backHome = () => {
  router.push({
    path: getRoute() + "home",
  });
};

// 返回用户中心
export const backUser = () => {
  router.push({
    path: getRoute() + "user/userManage",
  });
};

// 打开相册
export const openAlbum = (params) => {
  console.log(params);
};

export const openAction = () => {
  customAlert(
    {
      message: Language.plugins.unavailable,
      confirmButtonText: Language.plugins.download,
      cancelButtonText: Language.cancel
    },
    () => {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.prolinker.business";
    },
    () => {}
  );
};

export default {
  routerPush,
  shareGoods,
  pop,
  backHome,
  backUser,
  openAlbum,
  openAction,
};
