import Vue from "vue";
import { ImagePreview } from "vant";
Vue.use(ImagePreview);
//预览图片
export const picturePreview = (params) => {
  console.log("执行picturePreview");
  const { pictureList, startPosition } = params;
  let newPictureList = pictureList.map((item) => {
    return formatImageUrl(item);
  });
  ImagePreview(newPictureList, startPosition ? startPosition : 0);
  console.log(params);
};
//解析url
export const formatImageUrl = (url) => {
  const imageUrl = process.env.VUE_APP_BASH_IMAGE_URL;
  const bashImageUrl = imageUrl;
  if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    return url;
  }
  if (url.indexOf("/") === 0) {
    return bashImageUrl + url.slice(1);
  }
  return bashImageUrl + url;
};
export default {
  picturePreview,
};
