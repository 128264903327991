// 判断是否为app 或者h5页面
export const isAPP = (_) => {
  const app = /app/;
  let stringUrl = window.location.href;
  return app.test(stringUrl);
};
// 获取urltoken
export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return "-1";
};
export const getRoute = (noSlash = false) => {
  var app = "web";
  if (inApp()) {
    app = "app"; //客户端调用
  } else {
    app = "web"; //非客户端调用
  }
  if (noSlash) {
    return app;
  } else {
    return "/" + app + "/";
  }
};

//js判断页面位置是否在APP内部
export const inApp = () => {
  let isIphone = isHave("iPhone");//手机
  let isIpad1 = isHave("iPad");//平面电脑
  let isIpad2 = isHave("Macintosh");
  if (!isIphone) {
    // 安卓
    let haveVersion = isHave("Version");
    if (!haveVersion) {
      return false;
    } else {
      let haveWeibo = isHave("Weibo");
      let isBrowser = isHave("Browser");
      let WeChat = isHave("WeChat"); //微信浏览器打开
      let FaceBook = isHave("FBAV"); //facebook打开
      if (!isBrowser && !haveWeibo && !WeChat && !FaceBook) {
        return true;
      } else {
        return false;
      }
    }
  } else if (isIpad1 || isIpad2){
    return true;
  } else {
    // 苹果
    let haveQQ = isHave("QQ");
    let haveVersion = isHave("Version");
    let haveMicroMessenger = isHave("MicroMessenger");
    let WeChat = isHave("WeChat"); //微信浏览器打开
    let FaceBook = isHave("FBAV"); //facebook打开
    if (haveVersion || haveMicroMessenger || haveQQ || WeChat || FaceBook) {
      return false;
    } else {
      return true;
    }
  }
};
export const isHave = (m) => {
  let userAgent = navigator.userAgent;
  if (userAgent.indexOf(m) === -1) {
    return false;
  } else {
    return true;
  }
};

// 判断是ios系统还是安卓系统
export function getPlatform() {
  const userAgent = navigator.userAgent;
  if (userAgent.match(/Android/i)) {
    return 'android'
  } else if (userAgent.match(/iPhone/i)) {
    return 'ios'
  } else if (userAgent.match(/iPad/i)) {
    return 'ios'
  } else if (userAgent.match(/Macintosh/i)) {
    return 'ios'
  } else {
    return 'web'
  }
}

// 检测是否在开发者模式下
export function isProduction() {
  if (process.env.NODE_ENV === "production") {
    return true;
  } else {
    return false;
  }
}

export default {
  isAPP,
  inApp,
  isHave,
  getRoute,
  getQueryVariable,
  isProduction,
  getPlatform
};
