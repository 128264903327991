import moment from "moment";
import api from "@/api/api";
const instance = {
  filters: [
    {
      name: "formatDate",
      func: (value, format) => {
        return moment(value).format(format);
      }
    },
    {
      name: "formatPrice",
      func: value => {
        var str = value.toFixed(2) + "";
        var intSum = str
          .substring(0, str.indexOf("."))
          .replace(/\B(?=(?:\d{3})+$)/g, ","); // 取到整数部分
        var dot = str.substring(str.length, str.indexOf(".")); // 取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }
    },
    {
      name: "firstImageSrc",
      func: (value, separator = "|") => {
        const src = value.split(separator)[0];
        return api.formatImageUrl(src);
      }
    },
    {
      name: "imageSrc",
      func: value => {
        return api.formatImageUrl(value);
      }
    },
    {
      name: "expressState",
      func: value => {
        let stateString = "";
        value = value.toString();
        switch (value) {
          case "0":
            stateString = "发起交易";
            break;
          case "1":
            stateString = "关闭交易";
            break;
          case "20":
            stateString = "等待发货";
            break;
          case "24":
            stateString = "等待买家付款";
            break;
          case "30":
            stateString = "已发货";
            break;
          case "34":
            stateString = "仓库退回";
            break;
          case "40":
            stateString = "取消";
            break;
          case "41":
            stateString = "买家取消";
            break;
          case "42":
            stateString = "逾期取消";
            break;
          case "43":
            stateString = "订单无效取消";
            break;
          case "50":
            stateString = "客户签收";
            break;
          case "54":
            stateString = "客户拒签";
            break;
          default:
            stateString = "未知状态";
        }
        return stateString;
      }
    }
  ]
};

instance.install = function(Vue) {
  for (const filter of this.filters) {
    Vue.filter(filter.name, filter.func);
  }
};

export default instance;
