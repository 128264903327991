<template>
  <div class="app">
    <header v-if="!$route.meta.headerHide && $route.path != '/'">
      <van-nav-bar
        :title="$route.meta.title"
        :left-arrow="$route.meta.leftBtnHide ? false : true"
        @click-left="onClickLeft"
      ></van-nav-bar>
    </header>
    <div
      :class="[
        'middle',
        $route.path.includes('auth') || $route.path == '/' + route
          ? 'none-bottom'
          : 'mb-bottom',
      ]"
    >
      <router-view />
    </div>
    <footer>
      <van-tabbar
        v-show="$route.meta.footerShow"
        v-model="active"
        active-color="#04ab3f"
        inactive-color="#A8A9A9"
        safe-area-inset-bottom
      >
        <div class="blocker" v-if="showBlocker" @click="onAuth"></div>
        <van-tabbar-item
          v-for="(item, index) in tabbars"
          :key="index"
          :to="item.path"
          @click="onChange(index, item.path, item.name)"
        >
          <span>{{ item.title }}</span>
          <template slot="icon" slot-scope="props">
            <img :src="props.active ? item.active : item.normal" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import { Grid, GridItem, Icon, Button, Tabbar, TabbarItem, NavBar } from "vant";
import { getRoute } from "@environment/type";

Vue.use(NavBar);
export default {
  name: "App",
  components: {
    [Button.name]: Button,
    [Grid.name]: Grid,
    [Icon.name]: Icon,
    [GridItem.name]: GridItem,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      active: 0,
      route: "app",
      showBlocker: false,
      shopAuth: {},
      tabbars: [
        {
          path: getRoute() + "home",
          name: "Home",
          title: this.$t.meta.homePage,
          normal: require("@/assets/img/home/icon_tab_home.png"),
          active: require("@/assets/img/home/icon_tab_home_select.png"),
        },
        {
          path: getRoute() + "focus/follow",
          name: "Focus",
          title: this.$t.meta.followPage,
          normal: require("@/assets/img/home/icon_tab_like.png"),
          active: require("@/assets/img/home/icon_tab_like_select.png"),
        },
        {
          path: getRoute() + "user/userManage",
          name: "User",
          title: this.$t.meta.userPage,
          normal: require("@/assets/img/home/icon_tab_my.png"),
          active: require("@/assets/img/home/icon_tab_my_select.png"),
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      if (auth) {
        this.shopAuth.IsShopSubmit = auth.IsShopSubmit;
        this.shopAuth.IsShopCheck = auth.IsShopCheck;
        if (to.path.includes("home")) {
          if (
            this.shopAuth.IsShopSubmit == 1 &&
            this.shopAuth.IsShopCheck == 1
          ) {
          } else {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.routerPush,
              {
                isParams: false,
                path: "user/userManage",
                name: "User",
                query: [],
              }
            );
          }
        } else if (to.path.includes("focus")) {
          if (
            this.shopAuth.IsShopSubmit == 1 &&
            this.shopAuth.IsShopCheck == 1
          ) {
          } else {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.routerPush,
              {
                isParams: false,
                path: "user/userManage",
                name: "User",
                query: [],
              }
            );
          }
        } else if (to.path.includes("user")) {
          if (
            this.shopAuth.IsShopSubmit == 1 &&
            this.shopAuth.IsShopCheck == 1
          ) {
            this.showBlocker = false;
          } else {
            this.showBlocker = true;
          }
        }
      }
    },
  },
  methods: {
    onAuth() {
      this.$commonMethod.checkAuth(this.shopAuth);
    },
    onChange(index) {
      this.active = index;
      if (this.active == index) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    onClickLeft() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
    },
  },
  created() {
    this.active = 0;
    this.route = getRoute(true);
  },
  updated() {
    if (this.$route.path.includes("home")) {
      this.active = 0;
    } else if (this.$route.path.includes("focus")) {
      this.active = 1;
    } else if (this.$route.path.includes("user")) {
      this.active = 2;
    } else {
      this.active = 0;
    }
  },
};
</script>
<style scoped>
.app {
}
header {
  height: auto;
  background: linear-gradient(left, #71d283, #01aaa3);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
  height: auto;
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}
header img {
  width: 0.66rem;
  height: 0.57rem;
  vertical-align: middle;
}
footer {
  height: auto;
  line-height: 1rem;
  color: #fff;
}

footer img {
  width: 1.5rem;
  height: 1.5rem;
}

footer /deep/ .van-tabbar {
  height: auto;
  color: #04ab3f;
  z-index: 100 !important;
  padding-top: 5px;
  padding-bottom: calc(5px + env(safe-area-inset-bottom));
}
[class*="van-hairline"]::after {
  /* border: 0 solid #0f0f1a; */
}
.middle {
}

footer .blocker {
  position: absolute;
  width: 66.66%;
  height: 100%;
  z-index: 10;
  background: rgb(211, 211, 211, 0.2);
}
.none-bottom {
  margin-bottom: 0vw;
}
.mb-bottom {
  margin-bottom: 20vw;
}
</style>