import Vue from "vue";
import { Dialog } from "vant";
import { Language } from "../language";
import { routerPush } from "@environment/model/remould-router";
import { getRoute } from "@environment/type";

import {
  showToast,
  hideToast,
  showLoading,
  hideLoading,
} from "@/plugins/environment/model/toast"; // 轻提示+loading
import { showNotify } from "@/plugins/environment/model/notify";
// msg提示内容, method回调方法
export const dialogAlert = (msg, method) => {
  let dialog = Dialog;
  console.log("执行dialogAlert");
  dialog
    .alert({
      message: msg,
      className: "showDialogClass",
    })
    .then(() => {
      // on close
      method();
      dialog.close();
    });
};
// 弹框
export const dialogConfirm = (msg, confirm, cancel) => {
  let dialog = Dialog;
  console.log("dialogConfirm");
  dialog
    .confirm({
      message: msg,
      className: "showDialogClass",
    })
    .then(() => {
      console.log("你确认了操作");
      confirm();
    })
    .catch(() => {
      console.log("你取消了操作");
      cancel();
    });
};

//自定义弹窗
export const customAlert = (options, confirm, cancel) => {
  let dialog = Dialog;
  options.className = "showDialogClass";
  dialog
    .confirm(options)
    .then(() => {
      console.log("你确认了操作");
      confirm();
    })
    .catch(() => {
      console.log("你取消了操作");
      cancel();
    });
};

export const checkAuth = (value) => {
  var shopAuth = {};
  shopAuth.IsShopCheck = value.IsShopCheck;
  shopAuth.IsShopSubmit = value.IsShopSubmit;
  localStorage.setItem("shopAuth", null);
  localStorage.setItem("shopAuth", JSON.stringify(shopAuth));
  //未通过
  if (value.IsShopSubmit == 1 && value.IsShopCheck == -1) {
    customAlert(
      {
        title: Language.authenticationInfo.nameAuth,
        message: Language.authenticationInfo.failMessage,
        cancelButtonText: Language.alert.cancelButtonText,
        confirmButtonText: Language.alert.authButtonText
      },
      () => {
        routerPush({
          isParams: false,
          path: getRoute(true) + "/" + "authentication",
          name: "Authentication",
          query: [],
        });
      },
      () => {}
    );
  }
  //未认证
  if (value.IsShopCheck == 0 && value.IsShopSubmit == 0) {
    customAlert(
      {
        title: Language.authenticationInfo.nameAuth,
        message: Language.authenticationInfo.authMessage,
        cancelButtonText: Language.alert.cancelButtonText,
        confirmButtonText: Language.alert.authButtonText
      },
      () => {
        routerPush({
          isParams: false,
          path: getRoute(true) + "/" + "authentication",
          name: "Authentication",
          query: [],
        });
      },
      () => {}
    );
  }
  //审核中
  if (value.IsShopCheck == 0 && value.IsShopSubmit == 1) {
    customAlert(
      {
        title: Language.authenticationInfo.nameAuth,
        message: Language.authenticationInfo.submitMessage,
        cancelButtonText: Language.alert.cancelButtonText,
        confirmButtonText: Language.alert.confirmButtonText,
        showCancelButton: false,
      },
      () => {},
      () => {}
    );
  }
};

// 设置背景颜色
export const setBackgroundColor = (color) => {
  // console.log(color)
  document.body.style.backgroundColor = color;
};

Vue.prototype.$commonMethod = {
  dialogAlert,
  dialogConfirm,
  setBackgroundColor,
  showToast,
  hideToast,
  showLoading,
  hideLoading,
  showNotify,
  customAlert,
  checkAuth
};
